var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VMultiselect',{attrs:{"options":_vm.options,"track-by":"id","label":"name","placeholder":_vm.shouldShowPlaceholder ? '' : _vm.placeholder,"multiple":_vm.multiple,"allow-empty":_vm.multiple,"max":_vm.options.length || null,"close-on-select":!_vm.multiple,"hide-selected":_vm.multiple,"show-labels":false,"internal-search":!_vm.showDetails},on:{"search-change":function($event){_vm.showDetails && _vm.handleSearchChange($event)}},scopedSlots:_vm._u([(_vm.showDetails)?{key:"singleLabel",fn:function(ref){
var ref_option = ref.option;
var name = ref_option.name;
var account_name = ref_option.account_details.name;
return [_c('div',{staticClass:"option"},[_c('span',[_c('strong',[_vm._v(_vm._s(name))])]),_c('span',[_vm._v(_vm._s(account_name))])])]}}:null,(_vm.showDetails)?{key:"option",fn:function(ref){
var ref_option = ref.option;
var username = ref_option.username;
var account_name = ref_option.account_details.name;
return [_c('div',{staticClass:"option"},[_c('span',[_c('strong',[_vm._v(_vm._s(username))])]),_c('span',[_vm._v(_vm._s(account_name))])])]}}:null,{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noOptions'))+" ")]},proxy:true},{key:"noResult",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noResult'))+" ")]},proxy:true},{key:"maxElements",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.valueInternal),callback:function ($$v) {_vm.valueInternal=$$v},expression:"valueInternal"}})}
var staticRenderFns = []

export { render, staticRenderFns }