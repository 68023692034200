<template>
    <LocationForm ref="form" @save="save" />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { httpHelper } from '@/utils'
import LocationForm from '../LocationForm'

export default {
    name: 'CreateLocationView',
    components: {
        LocationForm,
    },
    computed: {
        ...mapGetters('auth', ['isAdminOrStaff']),
        changed() {
            return this.locationChanged || this.permissionsChanged
        },
        locationChanged() {
            const {
                name,
                description,
                geofence_tolerance,
            } = this.$refs.form.getValues()
            return (
                name ||
                description ||
                this.$refs.form.polygonDrawn ||
                geofence_tolerance
            )
        },
        permissionsChanged() {
            const {
                rolesViewingAccess,
                rolesEditingAccess,
            } = this.$refs.form.getPermissions()
            return (
                (rolesViewingAccess && rolesViewingAccess.length) ||
                (rolesEditingAccess && rolesEditingAccess.length)
            )
        },
    },
    methods: {
        ...mapMutations('location', ['addLocation']),
        async save(values) {
            this.$refs.form.setLoading(true)
            const { data } = await httpHelper.post('/locations/', values)
            this.addLocation(data)
            this.saved = true
            this.$refs.form.setLoading(false)
            this.$refs.form.saved(data.id)
            if (this.isAdminOrStaff) {
                this.manageAccess(data.id)
            }
        },
        async manageAccess(id) {
            const {
                rolesViewingAccess,
                rolesEditingAccess,
            } = this.$refs.form.getPermissions()

            rolesViewingAccess?.forEach(role => {
                httpHelper.post('/role-location-permissions/', {
                    location: id,
                    role,
                    access: 'READ',
                })
            })

            rolesEditingAccess?.forEach(role => {
                httpHelper.post('/role-location-permissions/', {
                    location: id,
                    role,
                    access: 'WRITE',
                })
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.changed || this.saved) {
            next()
            return
        }

        this.$refs.form.confirmLeave(this.$t('confirmLeave'), confirmed => {
            next(confirmed)
        })
    },
}
</script>

<i18n>
{
    "en": {
        "confirmLeave": "Really discard inputs?"
    },
    "de": {
        "confirmLeave": "Eingaben wirklich verwerfen?"
    },
    "fr": {
        "confirmLeave": "Souhaitez-vous réellement supprimer les entrées ?"
    }
}
</i18n>
