<template>
    <VMultiselect
        v-model="valueInternal"
        :options="options"
        track-by="id"
        label="name"
        :placeholder="shouldShowPlaceholder ? '' : placeholder"
        :multiple="multiple"
        :allow-empty="multiple"
        :max="options.length || null"
        :close-on-select="!multiple"
        :hide-selected="multiple"
        :show-labels="false"
        :internal-search="!showDetails"
        @search-change="showDetails && handleSearchChange($event)"
    >
        <template
            v-if="showDetails"
            #singleLabel="{ option: { name, account_details: { name: account_name } } }"
        >
            <div class="option">
                <span>
                    <strong>{{ name }}</strong>
                </span>

                <span>{{ account_name }}</span>
            </div>
        </template>

        <template
            v-if="showDetails"
            #option="{ option: { username, account_details: { name: account_name } } }"
        >
            <div class="option">
                <span>
                    <strong>{{ username }}</strong>
                </span>

                <span>{{ account_name }}</span>
            </div>
        </template>

        <template #noOptions>
            {{ $t('noOptions') }}
        </template>

        <template #noResult>
            {{ $t('noResult') }}
        </template>

        <template #maxElements>
            {{ $t('noOptions') }}
        </template>
    </VMultiselect>
</template>

<script>
import { mapGetters } from 'vuex'
import VMultiselect from 'vue-multiselect'

export default {
    name: 'RoleSelect',
    components: {
        VMultiselect,
    },
    props: {
        value: {
            type: [Number, Array],
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        exclude: {
            type: Array,
            default: () => [],
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
        }
    },
    computed: {
        ...mapGetters('auth', ['isAdminOrStaff']),
        ...mapGetters('role', ['rolesSortedByName']),
        shouldShowPlaceholder() {
            return this.multiple
                ? this.valueInternal && this.valueInternal.length
                : !!this.valueInternal
        },
        valueInternal: {
            get() {
                if (!this.value) {
                    return null
                }

                return this.multiple
                    ? this.options.filter(option =>
                          this.value.includes(option.id)
                      )
                    : this.options.find(option => this.value === option.id)
            },
            set(value) {
                this.$emit(
                    'input',
                    this.multiple ? value.map(item => item.id) : value.id
                )
            },
        },
    },
    watch: {
        exclude() {
            if (this.exclude?.length) {
                this.populateOptions()
            }
        },
    },
    mounted() {
        this.populateOptions()
    },
    methods: {
        populateOptions(searchQuery) {
            this.options = this.rolesSortedByName.filter(
                role =>
                    !this.exclude.includes(role.id) &&
                    (!searchQuery ||
                        role.name.toLowerCase().includes(searchQuery) ||
                        !role.account_details?.name ||
                        role.account_details.name
                            .toLowerCase()
                            .includes(searchQuery))
            )
        },
        handleSearchChange(query) {
            this.populateOptions(query.trim().toLowerCase())
        },
    },
}
</script>

<i18n>
{
    "en": {
        "noOptions": "No roles available",
        "noResult": "No roles were found"
    },
    "de": {
        "noOptions": "Keine Rollen verfügbar",
        "noResult": "Kein Rollen gefunden"
    },
    "fr": {
        "noOptions": "Aucun rôle disponible",
        "noResult": "Aucun rôle n'a été trouvé"
    },
    "it": {
        "noOptions": "Nessun ruoli disponibile",
        "noResult": "Nessun ruoli è stato trovato"
    }
}
</i18n>

<style lang="scss" scoped>
.option {
    display: flex;
    justify-content: space-between;

    span {
        &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:last-child {
            margin-left: 1em;
            font-weight: 700;
            opacity: 0.5;
            white-space: nowrap;
        }
    }

    strong {
        margin-right: 0.5em;
    }
}
</style>
